<template>
  <div>
    <div>
      <!--탑메뉴-->
      <topbar-comp></topbar-comp>
      <left-bar-comp>
        <sports-left-bar-comp></sports-left-bar-comp>
      </left-bar-comp>
      <div class="content_section">
        <div class="content">
          <sub-title>LIVE TV</sub-title>
          <div style="width: 100%;display: flex;justify-content: center;align-items: flex-start;margin-top: 10px">
            <iframe id="livetv" allowfullscreen="true" frameborder="0" scrolling="yes"
                    :src="iframDomain">
            </iframe>
          </div>
        </div>
      </div>
      <right-bar-comp>
        <!--내정보-->
        <user-info-comp slot="userinfo"></user-info-comp>
        <right-buttons-comp slot="btns"></right-buttons-comp>
        <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
      </right-bar-comp>
      <scroll-top-comp></scroll-top-comp>

    </div>

    <!--푸트메뉴-->
    <foot-comp></foot-comp>
  </div>
</template>

<script>
import LeftBarComp from "../../components/LeftBarComp";
import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
import SubTitle from "../../components/SubTitle";
import TopbarComp from "../../components/TopbarComp";
import RightBarComp from "../../components/RightBarComp";
import UserInfoComp from "../../components/UserInfoComp";
import RightButtonsComp from "../../components/RightButtonsComp";
import RightBarBannerComp from "../../components/RightBarBannerComp";
import ScrollTopComp from "../../components/ScrollTopComp";
import FootComp from "../../components/FootComp";
import leisureConst from "../../common/leisureConst";
import {postionMixin} from "@/common/mixin";

export default {

  mixins: [postionMixin],
  name: "SportsTV",
  components: {
    FootComp,
    ScrollTopComp,
    RightBarBannerComp,
    RightButtonsComp, UserInfoComp, RightBarComp, TopbarComp, SubTitle, SportsLeftBarComp, LeftBarComp
  },
  data() {
    return {
      iframDomain: null,
      position: "TV",
    }
  },
  created() {
    if (this.$store.state.ismobile) {
      this.iframDomain = 'https://amggsp.com/amg_tVdark/view/ninjatv/mobile.html'
    } else {
      this.iframDomain = 'https://amggsp.com/amg_tVdark/view/ninjatv/index.html'
    }
    console.log(this.iframDomain)
  }
}
</script>

<style scoped>
@import url("../../assets/css/base.css");
@import url("../../assets/css/common.css");
@import url("../../assets/css/style.css");
@import url("../../assets/css/media.css");

#livetv {
  overflow: hidden;
  width: 100%;
  height: 2500px;
}

@media screen and (max-width: 1024px) {
  #livetv {
    width: 375px !important;
    height: 1550px !important;
    overflow: hidden;

  }
}
</style>